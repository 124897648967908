import React, { useEffect, useRef } from "react"
import Chart from "chart.js/auto"
import "./chartStyle.css"

const Chart1 = ({ data }) => {
  const inputEl = useRef()
  useEffect(() => {
    const ctx = inputEl.current.getContext("2d")
    Chart.register({
      id: "marginlegend",
      beforeInit: function (chart, options) {
        // console.log("before Init", chart.legend)
        const originalFit = chart.legend.fit

        // Override the fit function
        chart.legend.fit = function fit() {
          // Call original function and bind scope in order to use `this` correctly inside it
          originalFit.bind(chart.legend)()
          // Change the height as suggested in another answers
          // this._margins.bottom = 200
          this.height += 15
        }
      },
      afterInit(chart, args, options) {
        // console.log("after Init", chart.legend)
      },
    })
    if (data?.labels?.length > 0) {
      new Chart(ctx, {
        type: "bar",
        data: {
          labels: data?.labels,
          datasets: [
            {
              data: data?.data1,
              barPercentage: 0.7,
              categoryPercentage: 0.2,
              label: "売上金額",
              borderColor: "#EBF0AC",
              backgroundColor: "#EBF0AC",
              fill: false,
              padding: 23,
            },
            {
              data: data?.data2,
              barPercentage: 0.7,
              categoryPercentage: 0.2,
              label: "前年同月",
              borderColor: "#D0C9F7",
              backgroundColor: "#D0C9F7",
              fill: false,
              padding: 23,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              type: "linear",
              ticks: {
                // Include a dollar sign in the ticks
                callback: function (value, index, ticks) {
                  return value + "万円"
                },
                maxTicksLimit: 6,
                color: "rgba(0, 0, 0, 0.85)",
                font: {
                  size: 12,
                  family: "Noto Sans JP",
                  weight: "400",
                  style: "normal",
                  lineHeight: "17px",
                },
              },
              grid: {
                drawTicks: false,
                borderDash: [4, 4], // <-- this draw dotter hiden grid area
                color: "rgba(0, 0, 0, 0.25)",
                drawBorder: false, // <-- this removes y-axis line
              },
            },
            x: {
              grid: {
                borderColor: "#000000",
                display: false,
              },
              ticks: {
                color: "rgba(0, 0, 0, 0.85)",
                font: {
                  size: 12,
                  family: "Noto Sans JP",
                  weight: "400",
                  style: "normal",
                  lineHeight: "17px",
                },
              },
            },
          },
          plugins: {
            title: {
              display: false,
              text: "Custom Chart Title",
            },
            legend: {
              display: true,
              labels: {
                color: "rgba(0, 0, 0, 0.85)",
                font: {
                  size: 10,
                  family: "Noto Sans JP",
                  weight: "400",
                  style: "normal",
                  lineHeight: "14px",
                },
                boxHeight: 8,
                boxWidth: 12,
              },
              align: "start",
            },
            marginlegend: true,
          },
        },
      })
    }
  }, [data])

  return (
    <div
      style={{
        height: "444px",
        width: "100%",
        backgroundColor: "#FFFFFF",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "20px auto 0px auto",
      }}
      className="aroundBlockBoder"
    >
      <div className="w-[100%] px-[50px]">
        <div className="flex justify-between">
          <div className="inline-block">
            <span className="redHeadText">売上</span>
            <span
              style={{
                background: "#ECB427",
                fontFamily: "Noto Sans",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "14px",
                lineheight: "21px",
                color: "#FFFFFF",
                marginLeft: "50px",
                padding: "2px 8px 2px 8px",
                position: "relative",
                top: "-4px",
              }}
            >
              売上金額
            </span>
            <span
              style={{
                width: "16px",
                height: "20px",
                fontfamily: "Noto Sans",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "16px",
                lineHeight: "21px",
                color: "#ECB427",
                marginLeft: "10px",
              }}
            >
              ¥
            </span>
            <span
              style={{
                width: "110px",
                height: "20px",
                fontfamily: "Noto Sans",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "24px",
                lineHeight: "21px",
                color: "#ECB427",
                marginLeft: "5px",
                position: "relative",
                top: "3px",
              }}
            >
              5,000,000
            </span>
            <span
              style={{
                fontFamily: "Noto Sans",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "13px",
                lineHeight: "100%",
                color: "#222222",
                marginLeft: "10px",
                position: "relative",
                top: "1px",
              }}
            >
              （商品代金 + 送料 - 返品金額）
            </span>
          </div>
          <span
            style={{
              fontFamily: "Noto Sans",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "13px",
              lineHeight: "165%",
              color: "rgba(34, 34, 34, 0.7)",
              position: "relative",
              top: "8px",
            }}
          >
            今月の目標売上：￥500,000
          </span>
        </div>
        <hr
          className="hrTag"
          style={{
            width: "100%",
          }}
        ></hr>

        <div
          style={{
            height: "264px",
            width: "100%",
            backgroundColor: "#FFFFFF",
            margin: "30px auto 0px auto",
          }}
        >
          <canvas ref={inputEl} />
        </div>
      </div>
    </div>
  )
}

export default Chart1
