import React, { useEffect, useRef } from "react"
import Chart from "chart.js/auto"
import "./chartStyle.css"
import { Select } from "antd"
const { Option } = Select
const handleChange = (value) => {
  console.log(`selected ${value}`)
}
const Chart3 = () => {
  const inputEl = useRef()
  useEffect(() => {
    const ctx = inputEl.current.getContext("2d")
    Chart.register({
      id: "marginlegend",
      beforeInit: function (chart, options) {
        console.log("before Init", chart.legend)
        const originalFit = chart.legend.fit

        // Override the fit function
        chart.legend.fit = function fit() {
          // Call original function and bind scope in order to use `this` correctly inside it
          originalFit.bind(chart.legend)()
          // Change the height as suggested in another answers
          // this._margins.bottom = 200
          this.height += 15
        }
      },
      afterInit(chart, args, options) {
        console.log("after Init", chart.legend)
      },
    })
    new Chart(ctx, {
      type: "bar",
      data: {
        labels: ["4/1", "4/2", "4/3", "4/4", "4/5", "4/6", "4/7"],
        datasets: [
          {
            data: [2500, 1000, 3000, 1700, 4300, 200, 700],
            barPercentage: 0.7,
            categoryPercentage: 0.2,
            label: "売上",
            borderColor: "#EBF0AC",
            backgroundColor: "#EBF0AC",
            fill: false,
            padding: 23,
          },
          {
            data: [900, 900, 500, 1000, 900, 200, 700],
            yAxisID: "yt",
            barPercentage: 0.7,
            categoryPercentage: 0.2,
            label: "PV",
            borderColor: "#7EBFE1",
            backgroundColor: "#7EBFE1",
            fill: false,
            padding: 23,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            type: "linear",
            ticks: {
              // Include a dollar sign in the ticks
              callback: function (value, index, ticks) {
                return value + "万円"
              },
              maxTicksLimit: 6,
              color: "rgba(0, 0, 0, 0.85)",
              font: {
                size: 12,
                family: "Noto Sans JP",
                weight: "400",
                style: "normal",
                lineHeight: "17px",
              },
            },
            grid: {
              borderDash: [4, 4], // <-- this draw dotter hiden grid area
              color: "rgba(0, 0, 0, 0.25)",
              drawBorder: false, // <-- this removes y-axis line
              drawTicks: false, // remove tick in y axis
            },
          },
          yt: {
            type: "linear",
            ticks: {
              // Include a dollar sign in the ticks
              callback: function (value, index, ticks) {
                return value + "PV"
              },
              maxTicksLimit: 6,
              color: "rgba(0, 0, 0, 0.85)",
              font: {
                size: 12,
                family: "Noto Sans JP",
                weight: "400",
                style: "normal",
                lineHeight: "17px",
              },
            },
            position: "right",
            grid: {
              display: false,
              drawBorder: false,
            },
          },
          x: {
            grid: {
              borderColor: "#000000",
              display: false,
            },
            ticks: {
              color: "rgba(0, 0, 0, 0.85)",
              font: {
                size: 12,
                family: "Noto Sans JP",
                weight: "400",
                style: "normal",
                lineHeight: "17px",
              },
            },
          },
        },
        plugins: {
          title: {
            display: false,
            text: "Custom Chart Title",
          },
          legend: {
            display: true,
            labels: {
              color: "rgba(0, 0, 0, 0.85)",
              font: {
                size: 10,
                family: "Noto Sans JP",
                weight: "400",
                style: "normal",
                lineHeight: "14px",
              },
              boxHeight: 8,
              boxWidth: 12,
            },
            align: "start",
          },
          marginlegend: true,
        },
      },
    })
  }, [])

  return (
    <div
      style={{
        height: "602px",
        width: "100%",
        backgroundColor: "#FFFFFF",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "20px auto 0px auto",
      }}
      className="aroundBlockBoder"
    >
      <div className="w-[100%] px-[50px]">
        <div>
          <span className="redHeadText">商品別データ</span>
        </div>
        <hr
          className="hrTag"
          style={{
            width: "100%",
          }}
        ></hr>
        <div className="w-[100%] h-[150px] bg-[#FAFAFA] mt-[9.5px]">
          <span
            style={{
              fontFamily: "Noto Sans JP",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "20px",
              textAlign: "left",
              letterSpacing: "0.3px",
              color: "#9B9B9B",
              display: "block",
            }}
            className="pt-[16px] ml-[20px]"
          >
            絞り込み条件
          </span>
          <div className="mt-[8px]">
            <span className="textNoTo700 ml-[20px]">カテゴリ</span>
            <Select
              defaultValue="商品大カテゴリ"
              className="dropDownButtonChart"
              onChange={handleChange}
              style={{ marginLeft: "40px" }}
            >
              <Option value="商品大カテゴリ">商品大カテゴリ</Option>
              <Option value="jack">Ronaldo</Option>
              <Option value="lucy">Lucan modric</Option>
              <Option value="Yiminghe">Toni kroos</Option>
              <Option value="Yiminghe">Karim benzema</Option>
            </Select>
            <Select
              defaultValue="商品大カテゴリ"
              className="ml-[4px] dropDownButtonChart"
              onChange={handleChange}
              style={{ marginLeft: "4px" }}
            >
              <Option value="商品大カテゴリ">商品大カテゴリ</Option>
              <Option value="jack">Ronaldo</Option>
              <Option value="lucy">Lucan modric</Option>
              <Option value="Yiminghe">Toni kroos</Option>
              <Option value="Yiminghe">Karim benzema</Option>
            </Select>
            <Select
              defaultValue="商品大カテゴリ"
              className="ml-[4px] dropDownButtonChart"
              onChange={handleChange}
              style={{ marginLeft: "4px" }}
            >
              <Option value="商品大カテゴリ">商品大カテゴリ</Option>
              <Option value="jack">Ronaldo</Option>
              <Option value="lucy">Lucan modric</Option>
              <Option value="Yiminghe">Toni kroos</Option>
              <Option value="Yiminghe">Karim benzema</Option>
            </Select>
            <span className="textNoTo700 ml-[68px]">キーワード</span>
            <div className="inline">
              <input
                type="text"
                id="lname"
                name="lname"
                placeholder="   商品名、JANコード、自社商品番号"
                className="w-[296px] h-[32px] aroundBlockBoder ml-[30px]"
              />
            </div>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button
              style={{
                width: "141px",
                height: "32px",
                background: "#EC4D43",
                border: "1px solid #EC4D43",
                borderRadius: "5px",
                color: "#FFFFFF",
                fontFamily: "Noto Sans",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "13px",
                lineHeight: "165%",
                marginTop: "26px",
              }}
            >
              検索
            </button>
          </div>
        </div>
        <div
          style={{
            height: "264px",
            width: "100%",
            backgroundColor: "#FFFFFF",
            margin: "30px auto 0px auto",
          }}
        >
          <canvas ref={inputEl} />
        </div>
      </div>
    </div>
  )
}

export default Chart3
